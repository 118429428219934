@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  
  width:100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.kumbh-font{
  font-family: 'kumbh';
}

@font-face {
  font-family: 'kumbh';
  src: url('./assets/Fonts/Kumbh.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.invite {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 10%;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;  
  width: 600px;
  height: 400px; 
  border: 2px solid #ccc;
  padding: 20px;
  position: relative;
}

.invite h1 {
  text-align: left;
  font-weight: 300;
  margin-bottom: 20px;
}

.invite-section {
  padding: 20px;
  margin-bottom: 20px;
}

.invite-section h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 300;
}

.invite-section form {
  display: flex;
  align-items: center;
}

.invite-section input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 8px;
}

.invite-section select {
  margin-left: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 8px;
}

.invite-section button {
  margin-left: 10px;
  padding: 10px;
  border: none;
  background-color: #6b4bc3;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  line-height: 15px;
}
.invite-section button:hover {
  background-color: #4c3392;
}
.copy {
  border: 1px solid #6b4bc3 !important;
  background-color: #ffffffe3 !important;
  color:#6b4bc3 !important ;
}
.copy:hover{
  border: none !important;
  background-color: #6b4bc3 !important;
  color:#ffffff !important ;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}
.success::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
}
.success-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 7rem;
  color: green;
  text-align: center;
}
.success-icon p {
  
  margin-top: 20px;
  
}
.success-message{
  font-size: 2rem;
  text-align: center;
}
@media only screen and (max-width: 890px) {
  .invite {
    width: 90%;
    height: auto;
    margin-top: 5%;
  }
  .invite h1 {
    font-size: 5vw;
  }
  .invite-section form {
    flex-direction: column;
  }
  .invite-section input,
  .invite-section select,
  .invite-section button {
    width: 100%;
    margin: 10px 0;
  }
}
